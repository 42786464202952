import { Skeleton } from '@mui/material';
import { lazy, Suspense, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styleComponents from '../style/pageStyle';
import { getNestedProperty } from '../utils/helper';
import ICONS from '../assets/icons';
import usePermission from '../hooks/usePermission';
import { EMPLOYER_MODULE_PERMISSIONS } from '../constants/permissions';
import { useGetApplication } from '../apis/queryHooks';
import styled from 'styled-components';
import CustomerPageHeader from '../components/customerDetails/CustomerPageHeader';

const Pagination = lazy(
  () => import('../components/atom/tableComponents/Pagination'),
);
const DisplayTable = lazy(() => import('../components/DisplayTable'));

const {
  Wrapper,
  Top,
  Bottom,
  HeaderWrap,
  Header,
  AnimatedBox,
  Details,
  TableDiv,
} = styleComponents();

const WhiteBox = styled.div`
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
`;
const Img = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  cursor: pointer;
`;
const DetailWrapper = styled.div`
  margin: 0px 40px;
`;

const Applications = () => {
  const isMounted = useRef(false);
  const { hasPermission } = usePermission();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [showActionsPanel, setShowActionsPanel] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDropDownBottom, setOpenDropDownBottom] = useState(false);
  const [actionIndex, setActionIndex] = useState('');
  const [actionOpen, setActionOpen] = useState(false);

  const handleLeftArrow = () => {
    navigate(-1);
  };

  const { data: allApplications } = useGetApplication({
    jobId,
    itemsPerPage: itemsPerPage,
    currentPage: currentPage,
  });

  useEffect(() => {
    if (allApplications?.noOfApplications)
      setTotalItems(allApplications?.noOfApplications);
  }, [allApplications]);

  const headerValues =
    allApplications?.headers?.map((item) => item.value) || [];
  const headerTypes = allApplications?.headers?.map((item) => item.type) || [];

  function createData(applicationsDetails) {
    const headerKeys = Array.from(
      allApplications?.headers.map((item) => item.key),
    );
    return headerKeys.map((item) => {
      const itemKey = item.replace(/['"]+/g, '');
      return getNestedProperty(applicationsDetails, itemKey);
    });
  }

  const rows =
    allApplications?.customerJobApplications?.map((item) => createData(item)) ||
    [];

  const onShowSizeChange = (pageSize) => {
    if (itemsPerPage !== pageSize) {
      setCurrentPage(1);
      setItemsPerPage(pageSize);
    }
  };

  const handleDropDownBottom = () => {
    setOpenDropDownBottom(!openDropDownBottom);
  };

  const handleViewApplication = (e) => {
    e.stopPropagation();
    navigate(
      `/applications/${allApplications?.customerJobApplications?.[actionIndex]?._id}`,
    );
  };

  const handleRowClick = (index) => {
    navigate(
      `/applications/${allApplications?.customerJobApplications?.[index]?._id}`,
    );
  };

  const arrBtn = [
    {
      text: 'View Details',
      icon: ICONS.EYE,
      active: true,
      isVisible: true,
      color: '#000',
      onClick: handleViewApplication,
      permission: EMPLOYER_MODULE_PERMISSIONS?.UPDATE_PROFILE_DETAILS,
    },
  ];

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (arrBtn.length > 0) {
      const hasAnyPermission = arrBtn.some((btn) =>
        hasPermission(btn.permission),
      );
      setShowActionsPanel(hasAnyPermission);
    }
  }, [arrBtn, hasPermission]);

  return (
    <Wrapper>
      <Top>
        <HeaderWrap>
          <Header>
            <WhiteBox onClick={() => handleLeftArrow()}>
              <Img
                src={ICONS.LEFT_ARROW_BLACK}
                alt="leftArrowBlack"
                width={'24px'}
                height={'24px'}
              />
            </WhiteBox>
          </Header>
        </HeaderWrap>
        <Suspense fallback={<div></div>}>
          <DetailWrapper>
            <CustomerPageHeader
              heading={
                allApplications?.title
                  ? allApplications?.title + ' - Applications'
                  : '-----'
              }
              subHeading={`Job ID ${jobId} - Total: ${totalItems ?? '-----'}`}
            />
          </DetailWrapper>
        </Suspense>
        <Suspense
          fallback={
            <AnimatedBox>
              {[1, 2, 3, 4, 5].map((item, idx) => {
                return <Skeleton animation="wave" height={70} key={idx} />;
              })}
            </AnimatedBox>
          }
        >
          <Details>
            <TableDiv>
              <DisplayTable
                tableId={'applicationTable'}
                highlightRow={true}
                rows={rows}
                headers={headerValues}
                showActionsPanel={showActionsPanel}
                headersType={headerTypes}
                tableWidth={'95%'}
                arrBtn={arrBtn}
                actionIndex={actionIndex}
                setActionIndex={setActionIndex}
                actionOpen={actionOpen}
                setActionOpen={setActionOpen}
                onClickFn={handleRowClick}
                arrBtnRight={'95px'}
              />
            </TableDiv>
          </Details>
        </Suspense>
      </Top>
      <Bottom>
        <Suspense fallback={<div></div>}>
          <Pagination
            onShowSizeChange={onShowSizeChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            arrowBg={'#fff'}
            isFlexColumn={false}
            isBottom={true}
            setOpenDropdown={setOpenDropDownBottom}
            openDropdown={openDropDownBottom}
            handleDropdown={handleDropDownBottom}
          />
        </Suspense>
      </Bottom>
    </Wrapper>
  );
};

export default Applications;
