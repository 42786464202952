import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import styleComponents from '../style/pageStyle';
import { useNavigate, useParams } from 'react-router-dom';
import ICONS from '../assets/icons';
import FileUpload from '../components/courses/FileUpload';
import { FILE_TYPES, MAX_VIDEO_API_TIMER } from '../constants';
import useFileUpload from '../hooks/useFileUpload';
import { generateUploadFilePath } from '../utils/helper';
import CustomCTA from '../components/CustomCTA';
import { ModalContext } from '../context/ModalProvider';
import IntroVideoModal from '../components/customerDetails/IntroVideoModal';
import { useGetJobById, usePutAddJob } from '../apis/queryHooks';
import { useSnackbar } from 'notistack';
import {
  MAX_JOB_VIDEO_FILE_DURATION_SEC,
  MAX_JOB_VIDEO_FILE_SIZE_MB,
} from '../constants/employer';

const { Top } = styleComponents();

const Wrapper = styled.div`
  margin: 61px 0 0 265px;
  min-height: calc(100vh - 3.5rem);
  background-color: #f4f6fa;
  padding: 16px 40px;
`;

const P = styled.p`
  color: #000;
  font-family: Poppins;
  font-size: ${(props) => props.$fontSize};
  font-style: normal;
  font-weight: ${(props) => props.$fontWeight};
  line-height: ${(props) => props.$lineHeight};
  margin-top: ${(props) => props.$marginTop || '0px'};
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const WhiteBox = styled.div`
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
`;

const Img = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  cursor: pointer;
`;

const StyledDiv = styled.div`
  margin-top: ${(props) => props.$marginTop || '20px'};
`;

const StyledLink = styled.div`
  text-decoration: underline;
  color: #141482;
  font-family: Poppins;
  font-size: 14px;
  cursor: pointer;
`;

const StyledSpan = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.$color || '#000'};
  font-family: Poppins;
  margin-top: 20px;
  display: block;
`;

const JobVideoUpload = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { displayModal } = useContext(ModalContext);
  const [tempVideoDelete, setTempVideoDelete] = useState(false);
  const [jobVideoUrl, setJobVideoUrl] = useState('');

  const { mutateAsync: addJobMutation, status: addJobStatus } = usePutAddJob();

  const { data: jobData } = useGetJobById(jobId);

  const {
    fileData: videoFileData,
    setFileData: setVideoFileData,
    handleInputChange: handleVideoInputChange,
    abortUpload: abortVideoUpload,
    fileSizeError: videoFileSizeError,
    fileDurationError: videoFileDurationError,
    data: videoUploadData,
    resetFileData: resetVideoFileData,
    status: videoUploadStatus,
  } = useFileUpload(
    generateUploadFilePath('JOB', `${jobId}`, FILE_TYPES?.VIDEO),
    FILE_TYPES?.VIDEO?.toUpperCase(),
    MAX_JOB_VIDEO_FILE_SIZE_MB,
    null,
    MAX_JOB_VIDEO_FILE_DURATION_SEC,
  );

  useEffect(() => {
    if (jobData?.video) {
      setJobVideoUrl(jobData?.video);
    } else {
      setJobVideoUrl('');
    }
  }, [jobData]);

  useEffect(() => {
    if (tempVideoDelete) {
      setJobVideoUrl('');
    }
  }, [tempVideoDelete]);

  useEffect(() => {
    if (videoUploadStatus === 'success') {
      setTempVideoDelete(false);
    }
  }, [videoUploadStatus]);

  const handleInputDelete = (type) => {
    if (type === FILE_TYPES?.VIDEO) {
      resetVideoFileData();
    }
  };

  const openVideoPlayer = (e, videoLink) => {
    e.stopPropagation();
    displayModal(
      <IntroVideoModal
        modalTitle="Job Video"
        aspectRatio="9/16"
        videoLink={videoLink}
      />,
      {
        modalWidth: '300px',
      },
    );
  };

  const handleCreateJobClick = () => {
    const data = {
      jobId: jobId,
      video: videoUploadData?.fileLink,
    };
    addJobMutation(data)
      .then((res) => {
        navigate(`/job/${jobId}`);
      })
      .catch((err) => {
        enqueueSnackbar('Error saving job', { variant: 'error' });
      });
  };
  const handleLeftArrow = () => {
    navigate(-1);
  };

  return (
    <Wrapper>
      <Top>
        <Header>
          <WhiteBox onClick={() => handleLeftArrow()}>
            <Img
              src={ICONS.LEFT_ARROW_BLACK}
              alt="leftArrowBlack"
              width={'24px'}
              height={'24px'}
            />
          </WhiteBox>
        </Header>
        <HeaderWrap>
          <P
            $fontSize={'24px'}
            $fontWeight={'600'}
            $lineHeight={'normal'}
            $marginTop="20px"
          >
            Upload Video - {jobData?.title}
          </P>
        </HeaderWrap>
      </Top>
      <StyledDiv $marginTop="30px">
        <FileUpload
          fileData={videoFileData}
          fileType={FILE_TYPES?.VIDEO}
          iconUrl={ICONS?.VIDEO_CAMERA_BLUE}
          uploadTitle={'Upload Video'}
          acceptType={'video/mp4, video/quicktime'}
          handleInputChange={(e) =>
            handleVideoInputChange(e, FILE_TYPES?.VIDEO)
          }
          uploadData={jobVideoUrl}
          handleInputDelete={handleInputDelete}
          abortUpload={abortVideoUpload}
          maxApiTimer={MAX_VIDEO_API_TIMER}
          tempDelete={tempVideoDelete}
          setTempDelete={setTempVideoDelete}
        />
        {videoFileData?.fileName || jobVideoUrl ? null : (
          <StyledSpan>Supported Format : MP4/MOV (Max size 50MB)</StyledSpan>
        )}
      </StyledDiv>
      {(videoFileData?.fileName && videoUploadStatus === 'success') ||
      jobVideoUrl ? (
        <StyledDiv $marginTop="20px">
          <StyledLink
            onClick={(e) =>
              openVideoPlayer(e, videoUploadData?.fileLink || jobVideoUrl)
            }
          >
            View Video
          </StyledLink>
        </StyledDiv>
      ) : null}
      {videoFileSizeError ? (
        <StyledSpan $color="red">File size should be less than 50MB</StyledSpan>
      ) : null}
      {videoFileDurationError ? (
        <StyledSpan $color="red">
          File duration should be less than {MAX_JOB_VIDEO_FILE_DURATION_SEC}{' '}
          Seconds
        </StyledSpan>
      ) : null}
      <StyledDiv>
        <CustomCTA
          onClick={() => handleCreateJobClick()}
          disabled={
            (!videoFileData?.fileName && !jobVideoUrl) ||
            videoUploadStatus === 'pending'
          }
          title={jobData?.video ? 'Update Job' : 'Create Job'}
          showSecondary={true}
          color="#ffffff"
          bgColor={'#141482'}
          border={'1px solid #CDD4DF'}
        />
      </StyledDiv>
    </Wrapper>
  );
};

export default JobVideoUpload;
