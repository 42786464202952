import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import BasicDetails from '../components/employers/BasicDetails';
import BusinessProof from '../components/employers/BusinessProof';
import CompanyAddress from '../components/employers/CompanyAddress';
import CustomCTA from '../components/CustomCTA';
import styleComponents from '../style/pageStyle';
import {
  useGetCityStateByPincode,
  useGetEmployerDetails,
  useGetGlobalEmployerData,
  useGetWebsiteDomainCheck,
  usePostAddEmployer,
  usePutUpdateEmployerStatus,
} from '../apis/queryHooks';

import ICONS from '../assets/icons';
import LogoUpload from '../components/employers/LogoUpload';
import useAddEmployer from '../hooks/employer/useAddEmployer';
import { COMPANY_FORM_DETAILS } from '../constants/employer';

const LeftArrow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const Left = styled.div`
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
`;

const Img = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  cursor: pointer;
`;

const StyledHeader = styled.div`
  margin-top: 20px;
`;

const HeaderWrap = styled.div`
  padding: 20px;
`;

const Content = styled.div`
  margin-top: 10px;
`;

const Bottom = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const { Wrapper, Top, Header, HeaderTitle } = styleComponents();
const AddEmployer = () => {
  const [errors, setErrors] = useState({});
  const [domain, setDomain] = useState('');
  const [employerData, setEmployerData] = useState(COMPANY_FORM_DETAILS);
  const [logoUrl, setLogoUrl] = useState('');
  const [pincode, setPincode] = useState('');

  const [searchParams] = useSearchParams();

  const agencyType = searchParams?.get('agencyType');
  const employerId = searchParams?.get('id');
  const isEditMode = Boolean(employerId);

  const navigate = useNavigate();
  const { data: globalData } = useGetGlobalEmployerData();

  const {
    data: cityStateData,
    status: cityStateDataStatus,
    refetch: cityStateDataRefetch,
  } = useGetCityStateByPincode(pincode);

  const {
    data: checkDomainData,
    isLoading: isCheckDomainDataLoading,
    refetch: checkDomainDataRefetch,
    error: checkDomainDataError,
  } = useGetWebsiteDomainCheck(domain);

  const { data: employerDetailsData } = useGetEmployerDetails(employerId);

  const {
    mutateAsync: updateEmployerStatusMutation,
    status: updateEmployerStatus,
    error: updateEmployerStatusErr,
  } = usePutUpdateEmployerStatus(employerId);

  const { mapResponseToFormDetails, generateEmployerPayload, validateFields } =
    useAddEmployer({
      employerData,
      setEmployerData,
      domain,
      checkDomainDataRefetch,
      pincode,
      cityStateDataRefetch,
      setErrors,
      agencyType,
      employerDetailsData,
    });

  useEffect(() => {
    if (cityStateDataStatus === 'success') {
      setEmployerData((prev) => ({
        ...prev,
        city: cityStateData?.city || '',
        state: cityStateData?.state || '',
      }));
    } else if (cityStateDataStatus === 'error') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pincode: 'Invalid Pincode',
      }));
      setEmployerData((prev) => ({
        ...prev,
        city: '',
        state: '',
      }));
    }
  }, [cityStateDataStatus]);

  useEffect(() => {
    if (employerDetailsData?.companyWebsiteUrl)
      setDomain(employerDetailsData?.companyWebsiteUrl);
    if (employerDetailsData?.currentAddress?.pincode)
      setPincode(employerDetailsData?.currentAddress?.pincode);
    setLogoUrl(employerDetailsData?.companyLogoUrl);
  }, [employerDetailsData]);

  useEffect(() => {
    setEmployerData((prev) => ({
      ...prev,
      city: cityStateData?.city || '',
      state: cityStateData?.state || '',
    }));
  }, [cityStateData]);

  const handleBlur = () => {
    setDomain(employerData?.companyWebsiteURL);
  };

  useEffect(() => {
    setEmployerData((prev) => ({
      ...prev,
      companyLogoUrl: logoUrl,
    }));
  }, [logoUrl]);

  useEffect(() => {
    if (isEditMode && employerDetailsData) {
      const preFilledEmployerData =
        mapResponseToFormDetails(employerDetailsData);
      setEmployerData(preFilledEmployerData);
    }
  }, [isEditMode, employerDetailsData]);

  const {
    mutate: postAddEmployerMutate,
    status: postAddEmployerStatus,
    error: postAddEmployerError,
  } = usePostAddEmployer();

  useEffect(() => {
    if (postAddEmployerStatus === 'success') {
      setErrors({});
      enqueueSnackbar('Employer added successfully', {
        variant: 'success',
      });
      navigate('/employers');
    } else if (postAddEmployerStatus === 'error') {
      if (postAddEmployerError?.response?.data?.error?.message) {
        enqueueSnackbar(postAddEmployerError?.response?.data?.error?.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Failed to add employer', {
          variant: 'error',
        });
      }
    }
  }, [postAddEmployerStatus]);

  useEffect(() => {
    if (updateEmployerStatus === 'success') {
      setErrors({});
      enqueueSnackbar('Employer updated successfully', {
        variant: 'success',
      });
      navigate(-1);
    } else if (updateEmployerStatus === 'error') {
      if (postAddEmployerError?.response?.data?.error?.message) {
        enqueueSnackbar(postAddEmployerError?.response?.data?.error?.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Failed to update employer', {
          variant: 'error',
        });
      }
    }
  }, [updateEmployerStatus]);

  const handleLeftArrow = () => {
    navigate(-1);
  };

  const handleCreateEmployer = async () => {
    const isValidData = await validateFields(
      employerData,
      domain,
      checkDomainDataRefetch,
      setErrors,
    );

    if (isValidData) {
      const payload = generateEmployerPayload(
        employerData,
        agencyType,
        employerDetailsData,
      );
      if (isEditMode) {
        updateEmployerStatusMutation(payload);
      } else {
        postAddEmployerMutate(payload);
      }
    } else if (postAddEmployerError || updateEmployerStatusErr) {
      enqueueSnackbar(`Failed to ${isEditMode ? 'add' : 'update'} employer`, {
        variant: 'error',
      });
    }
  };

  return (
    <Wrapper>
      <Top>
        <HeaderWrap>
          <Header>
            <LeftArrow>
              <Left onClick={() => handleLeftArrow()}>
                <Img
                  src={ICONS?.LEFT_ARROW_BLACK}
                  alt="leftArrowBlack"
                  width={'24px'}
                  height={'24px'}
                />
              </Left>
            </LeftArrow>
            <StyledHeader>
              <HeaderWrapper>
                <HeaderTitle>
                  {isEditMode ? 'Edit Account' : 'Create Account'}
                </HeaderTitle>
                <LogoUpload
                  initialIcon={logoUrl ? logoUrl : ICONS.UPLOAD_LOGO}
                  loadingIcon={ICONS.UPLOADING_LOGO}
                  setImage={setLogoUrl}
                  imageUrl={logoUrl}
                  maxFileSizeInMB={5}
                />
              </HeaderWrapper>
              <Content>
                <BasicDetails
                  data={employerData}
                  setData={setEmployerData}
                  errors={errors}
                  setErrors={setErrors}
                  isEditMode={isEditMode}
                />
                <BusinessProof
                  data={employerData}
                  setData={setEmployerData}
                  errors={errors}
                  setErrors={setErrors}
                  globalData={globalData}
                  handleBlur={handleBlur}
                  checkDomainData={checkDomainData}
                  isCheckDomainDataLoading={isCheckDomainDataLoading}
                  checkDomainDataError={checkDomainDataError}
                  domain={domain}
                />
                <CompanyAddress
                  data={employerData}
                  setData={setEmployerData}
                  errors={errors}
                  setErrors={setErrors}
                  setPincode={setPincode}
                />
              </Content>
            </StyledHeader>
          </Header>
        </HeaderWrap>
      </Top>

      <Bottom>
        <CustomCTA
          onClick={handleCreateEmployer}
          title={isEditMode ? 'Update Details' : 'Create Account'}
          color={'#FFF'}
          bgColor={'#141482'}
          border={'1px solid #CDD4DF'}
          disabled={!employerData?.companyName || !employerData?.workEmail}
        />
      </Bottom>
    </Wrapper>
  );
};

export default AddEmployer;
