import React, { useEffect, useState, lazy, Suspense } from 'react';
import styled from 'styled-components';
import ICONS from '../../assets/icons';
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';

const EmployerDetailsTab = lazy(() => import('./EmployerDetailsTabs'));
import { useGetEmployerDetails } from '../../apis/queryHooks';
import BusinessVerificationPage from './BusinessVerificationPage';
import DirectAndRecEmployerTab from './DirectAndRecEmployerTab';
import CustomCTA from '../CustomCTA';
import { PAGE_PERMISSIONS } from '../../constants/permissions';
import usePermission from '../../hooks/usePermission';
import { agencyType, agencyTypeStyles } from '../../constants/employer';
import { JOB_TAB } from '../../constants';
const CustomerPageHeader = lazy(
  () => import('../customerDetails/CustomerPageHeader'),
);

const Wrapper = styled.div`
  margin: 61px 0 0 265px;
  min-height: calc(100vh - 3.5rem);
  background-color: #f4f6fa;
  padding: 16px 40px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
`;

const HeaderRight = styled.div`
  margin: 10px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Img = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  cursor: pointer;
`;

const StatusPill = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 12px;
  background-color: ${({ bgColor }) => bgColor || '#f0f0f0'};
  color: ${({ textColor }) => textColor || '#333'};
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  border: 1px solid ${({ borderColor }) => borderColor || '#dcdcdc'};
  font-family: Poppins;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; /* Reduce the gap between elements */
  flex-wrap: nowrap; /* Prevent wrapping */
`;

const EmployerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { hasPermission } = usePermission();
  const employerId = location.pathname.substring(11, location.pathname.length);
  const [showBusinessVerificationPage, setShowBusinessVerificationPage] =
    useState(false);
  const [pageRoute, setPageRoute] = useState('');

  /** For Direct Employers and Rec. Agency */
  const [searchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const isJobTab = searchParams.get('tab') === JOB_TAB;
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [totalFiltersCount, setTotalFiltersCount] = useState(0);

  const {
    data: employerData,
    isLoading: isEmployerDataLoading,
    isFetching: isEmployerDataFetching,
    isError: isEmployerDataError,
    error: employerDataError,
    refetch: refetchEmployerData,
  } = useGetEmployerDetails(employerId);

  const canPostJob =
    employerData?.verificationStatus === 'VERIFIED' &&
    employerData?.activationStatus === 'ACTIVATED';
  useEffect(() => {
    setSelectedTab(0);
  }, [id]);

  useEffect(() => {
    if (isJobTab) {
      setSelectedTab(1);
      return;
    }
    setSelectedTab(0);
  }, [isJobTab]);

  useEffect(() => {
    if (isEmployerDataError) {
      enqueueSnackbar('Error while fetching employer details', {
        variant: 'error',
      });
    }
  }, [isEmployerDataError]);

  const handleLeftArrow = () => {
    navigate(-1);
  };

  const handleAddNewJob = (index) => {
    if (!hasPermission(PAGE_PERMISSIONS?.VIEW_EMPLOYER_DETAILS)) return;
    sessionStorage?.removeItem('jobId');
    navigate(`/employers/${employerId}/add-job`, {
      state: {
        employerName: employerData?.companyRegisteredName,
      },
    });
  };

  if (showBusinessVerificationPage) {
    return (
      <BusinessVerificationPage
        employerData={employerData}
        setShowBusinessVerificationPage={setShowBusinessVerificationPage}
        isEmployerDataLoading={isEmployerDataLoading}
        isEmployerDataFetching={isEmployerDataFetching}
        employerDataError={employerDataError}
        refetchEmployerData={refetchEmployerData}
        pageRoute={pageRoute}
        setPageRoute={setPageRoute}
      />
    );
  }

  return (
    <Wrapper>
      <Header>
        <Left onClick={() => handleLeftArrow()}>
          <Img
            src={ICONS.LEFT_ARROW_BLACK}
            alt="leftArrowBlack"
            width={'24px'}
            height={'24px'}
          />
        </Left>
      </Header>
      <Suspense fallback={<div></div>}>
        <HeaderWrap>
          <FlexWrapper>
            <div>
              <CustomerPageHeader
                heading={employerData?.companyRegisteredName ?? '-----'}
                subHeading={`Company ID: ${employerData?.uniqueAgencyId ?? '-----'}`}
              />
            </div>
            <StatusPill
              bgColor={
                agencyTypeStyles[employerData?.employersAgencyType]?.bgColor ||
                '#f0f0f0'
              }
              textColor={
                agencyTypeStyles[employerData?.employersAgencyType]
                  ?.textColor || '#333'
              }
              borderColor={
                agencyTypeStyles[employerData?.employersAgencyType]?.bgColor ||
                '#dcdcdc'
              }
            >
              {agencyType[employerData?.employersAgencyType]}
            </StatusPill>
          </FlexWrapper>
          {selectedTab === 1 && (
            <HeaderRight>
              <CustomCTA
                onClick={() => setOpenFilterDrawer(true)}
                url={ICONS.FILTER}
                disabled={!canPostJob}
                title={`Filter (${totalFiltersCount ?? ''})`}
                showIcon={true}
                bgColor={'#677995'}
                color={'#FFF'}
                border={'none'}
                fontSize={'12px'}
                gap={'12px'}
              />
              <CustomCTA
                onClick={handleAddNewJob}
                disabled={!canPostJob}
                title={'New Job'}
                showIcon={true}
                color={'#FFF'}
                bgColor={'#141482'}
                border={'1px solid #CDD4DF'}
              />
            </HeaderRight>
          )}
        </HeaderWrap>
      </Suspense>
      <Suspense fallback={<div></div>}>
        {employerData?.employersAgencyType === 'DIRECT_EMPLOYER' ||
        employerData?.employersAgencyType === 'RECRUITMENT_AGENCY' ? (
          <DirectAndRecEmployerTab
            currentIndex={employerData}
            employerDataLoading={isEmployerDataLoading}
            employerDataFetching={isEmployerDataFetching}
            employerDataError={isEmployerDataError}
            refetchEmployerData={refetchEmployerData}
            setShowBusinessVerificationPage={setShowBusinessVerificationPage}
            setPageRoute={setPageRoute}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            openFilterDrawer={openFilterDrawer}
            setOpenFilterDrawer={setOpenFilterDrawer}
            totalFiltersCount={totalFiltersCount}
            setTotalFiltersCount={setTotalFiltersCount}
          />
        ) : (
          <EmployerDetailsTab
            currentIndex={employerData}
            employerDataLoading={isEmployerDataLoading}
            employerDataFetching={isEmployerDataFetching}
            employerDataError={isEmployerDataError}
            refetchEmployerData={refetchEmployerData}
            setShowBusinessVerificationPage={setShowBusinessVerificationPage}
            setPageRoute={setPageRoute}
          />
        )}
      </Suspense>
    </Wrapper>
  );
};

export default EmployerDetails;
