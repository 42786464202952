export const USER_TYPE = 'OPS';

export const MAX_FILENAME_LENGTH = 15;
export const MAX_IMAGE_API_TIMER = 10 * 1000;
export const MAX_VIDEO_API_TIMER = 6 * 60 * 1000;
export const COOKIES_MAX_AGE = 24 * 60 * 60;

export const SHORTENED_LENGTH = 13;

export const BENEFIT_STRUCTURE = {
  salaryBenefit: '',
  trainingCertificate: '',
  trainingReward: '',
};

export const COURSE_MODULE = {
  ASSESSMENT_CATEGORIES: [
    'COURSE_SUB_MODULE_LEVEL',
    'MID_COURSE_LEVEL',
    'END_COURSE_LEVEL',
  ],

  REWARD_STRUCTURE: [
    'certificateBenefits',
    'trophyBenefits',
    'badgeBenefits',
    'trainingBenefits',
  ],

  COURSE_OBJ_STRUCTURE: {
    courseTitle: '',
    courseDescription: '',
    courseCategory: '',
    price: {
      coursePrice: 0,
      displayPrice: 0,
    },
    salaryBenefit: '',
    courseIntroVideo: '',
    imageUrl: '',
    certificateBenefits: BENEFIT_STRUCTURE,
    trophyBenefits: BENEFIT_STRUCTURE,
    badgeBenefits: BENEFIT_STRUCTURE,
    trainingBenefits: BENEFIT_STRUCTURE,
  },

  COURSE_OBJ_ERROR_STRUCTURE: {
    courseTitle: false,
    courseDescription: false,
    courseCategory: false,
    coursePrice: false,
    displayPrice: false,
    salaryBenefit: false,
  },

  ASSESSMENT_OBJ_ERROR_STRUCTURE: {
    assessmentTitle: false,
    assessmentType: false,
    assessmentDescription: false,
  },

  QUESTION_TYPES: ['FUNCTIONAL', 'PSYCHOMETRIC'],

  OPTION_TYPES: {
    TEXT: 'TEXT',
    TEXT_IMAGE: 'TEXT_IMAGE',
  },

  PAGE_SIZE_OPTIUONS: [10, 20, 30, 40],

  TITLE_MAX_LENGTH: 500,
  DESCRIPTION_MAX_LENGTH: 2000,
  COURSE_PRICE_MAX: 10000,
  DISPLAY_PRICE_MAX: 10000,

  DEFAULT_MAX: 500,
  DEFAULT_MIN: 0,

  PASSING_PERCENT: 0,
  DEFAULT_TIME_DIFF: 13,
};

export const CUSTOMER_MODULE = {
  CUSTOMER_TAB_HEADERS: [
    'TrueID',
    'Transactions',
    'Orders',
    'Training',
    'Referral',
  ],
};

export const FILE_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document',
  AUDIO: 'audio',
  CUSTOMER_VIDEO: 'customer_video',
};

export const PAYMENT_TAB_HEADERS = ['Incoming Payments', 'Refund Payments'];

export const USER_TYPE_CUSTOMER = 'CUSTOMER';

export const VIDEO_UPLOAD_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

export const TEXT_MIN_SIZE = 10;

export const STATUS_KEYS = {
  NOT_INITIATED: 'NOT_INITIATED',
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
  OPS_VERIFIED: 'OPS_VERIFIED',
  OPS_REJECTED: 'OPS_REJECTED',
};

export const REMARKS_MIN_LIMIT = 15;
export const REMARKS_MAX_LIMIT = 1000;

export const MAX_INTRO_VIDEO_FILE_SIZE_MB = 150;
export const MAX_VIDEO_FILE_SIZE_MB = 500;

export const MAX_DOC_IMAGE_FILE_SIZE_MB = 10;

export const STATUS_VALUES = {
  NOT_INITIATED: 'Yet to Start',
  PENDING: 'Pending',
  VERIFIED: 'Verified',
  REJECTED: 'Rejected',
};

export const USERS_MODULE = {
  USER_TAB_HEADERS: ['Users', 'Roles', 'Permissions'],
};

export const DEVICE_TYPES = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

export const ACCEPT_TYPE = {
  IMAGE: 'image/*',
  VIDEO: 'video/*',
  AUDIO: 'audio/*',
  DOCUMENT: 'application/pdf',
  CSV: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
};

export const MAX_LENGTHS = {
  CIN: 21,
  GSTIN: 15,
  PAN: 10,
  LLPIN: 7,
  AADHAAR: 12,
  ADDRESS: 30,
  PIN_CODE: 6,
  CITY: 30,
};

export const JOB_TAB = 'jobs';
export const PROFILE_TAB = 'profile';

export const INCOMING_PAYMENT_FILTERS = [
  { key: 'COMPLETED', value: 'Completed', checked: false },
  { key: 'PENDING', value: 'Pending', checked: false },
  { key: 'FAILED', value: 'Failed', checked: false },
  { key: 'CANCELLED', value: 'Cancelled', checked: false },
];

export const REFUND_PAYMENT_FILTERS = [
  { key: 'COMPLETED', value: 'Completed', checked: false },
  { key: 'PENDING', value: 'Pending', checked: false },
  { key: 'FAILED', value: 'Failed', checked: false },
];
