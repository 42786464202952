import { useEffect, useState } from 'react';

export const useParseApplicantDetails = (applicantDetails) => {
  const [statusMap, setStatusMap] = useState({});

  useEffect(() => {
    Object.keys(applicantDetails?.applicationStatus || {}).map((key) => {
      const data = applicantDetails?.applicationStatus[key];
      setStatusMap((prevState) => ({
        ...prevState,
        [key]: {
          ...data,
          nextPossibleStates: data?.nextPossibleStates || [],
        },
      }));
    });
  }, [applicantDetails]);

  const applicantBioData = {
    name: applicantDetails?.customerDetails?.name || '-----',
    'Job ID': applicantDetails?.jobId || '-----',
    jobTitle: applicantDetails?.job?.title || '-----',
    employer: applicantDetails?.job?.employerName || '-----',
    biodataVideoLink:
      applicantDetails?.biodataDetails?.customerBioDataVideo || '-----',
    interviewVideoLink:
      applicantDetails?.interviewDetails?.interviewLink || '-----',
  };

  const jobStatus = applicantDetails?.status || '-----';
  return { applicantBioData, jobStatus, statusMap };
};
